<!-- Tela dos DADOS PESOAIS do paciente -->
<template>
  <div class="pa-4">
    <v-form @submit.prevent="localOnSubmit" ref="formRef">
      <div class="grid-container">
        <v-text-field class="flex-item-nome" dense name="nome" v-model="currentItem.nome" outlined :rules="requiredField" clearable @input="formatPatientName">
          <template v-slot:label>
            <span>Nome completo <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field dense name="cpf" label="CPF" v-model="currentItem.cpf" outlined v-mask="'###.###.###-##'" :rules="cpfRules" clearable > </v-text-field>
        <v-text-field dense name="rg" label="RG" v-model="currentItem.rg" outlined clearable />
        <v-autocomplete dense name="orgao_exp" label="Orgão expedidor" v-model="currentItem.orgao_exp" outlined
        :items="issuingAgencies" item-text="nome" item-value="id" return-object clearable />
        <v-text-field dense name="data_nascimento" v-model="currentItem.data_nascimento"
        outlined type="date" :rules="requiredField" clearable>
        <template v-slot:label>
          <span>Data de nascimento <span style="color: red;">*</span></span>
        </template>
        <!-- Slot para mostrar a idade ao lado da data   485.250.840-26 -->
          <template v-slot:append>
            <span v-if="idadePaciente !== ''" class="idade-text"> {{ idadePaciente }} anos</span>
          </template>
      </v-text-field>
     
      <v-select dense :rules="requiredField" :items="biologicalSex" item-text="label" item-value="value" v-model="currentItem.sexo_biologico" outlined>
        <template v-slot:label>
          <span>Sexo biológico <span style="color: red;">*</span></span>
        </template>
      </v-select>
      <v-select class="flex-item-maximo" :items="liminarOpts" label="Necesssita Responsável?" item-text="label" item-value="value" v-model="currentItem.necessita_responsavel" outlined dense/>
      <v-select class="flex-item-maximo" :items="respiradorOpts" label="Possui Respirador?" item-text="label" item-value="value" v-model="currentItem.respirador" outlined dense/>
      <!-- Campo Responsável pelo paciente -->
        <v-text-field class="flex-item" dense name="responsavel" v-model="currentItem.responsavel" outlined :rules="responsavelRules" clearable>
          <template v-slot:label>
            <span>Responsável pelo paciente<span v-if="currentItem.necessita_responsavel" style="color: red;">*</span></span>
          </template>
        </v-text-field>

        <!-- Campo CPF do Responsável -->
        <v-text-field dense name="cpf_responsavel" label="CPF do Responsável" v-model="currentItem.cpf_responsavel" v-mask="'###.###.###-##'" :rules="cpfRules" outlined clearable>
          <template v-slot:label>
            <span>CPF do Responsável<span v-if="currentItem.necessita_responsavel" style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field dense name="telefone" v-model="currentItem.telefone" outlined v-mask="'(##) #####-####'" :rules="requiredField" clearable>
          <template v-slot:label>
            <span>Telefone <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
        <v-text-field dense name="email" label="E-mail" v-model="currentItem.email" outlined clearable :rules="emailRules"/>
        <v-autocomplete dense name="cid" v-model="currentItem.cid" outlined :items="cids" item-text="nome" item-value="id" return-object >
          <template v-slot:label>
            <span>Código do CID <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        <v-autocomplete dense name="cid" v-model="currentItem.cid" outlined :items="cids" item-text="descricao" item-value="id" return-object :rules="requiredField" >
          <template v-slot:label>
            <span>Descrição do CID <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        <v-autocomplete dense name="operadora" v-model="currentItem.operadora" outlined
          :items="operators" item-text="nome" item-value="id" return-object :rules="requiredField" clearable>
          <template v-slot:label>
            <span>Operadora <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        <v-autocomplete dense name="plano" :rules="requiredField" v-model="currentItem.plano" outlined
          :loading="isLoading('get:plans')" :items="plans" item-text="nome" item-value="id" return-object clearable>
          <template v-slot:label>
            <span>Plano <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        <v-text-field dense name="nr_carteirinha" label="Nº carteirinha operadora" v-model="currentItem.nr_carteirinha" outlined clearable style="width: 250px;" type="Number"/>
        <v-text-field dense name="validade" label="Validade" v-model="currentItem.validade" outlined type="date" clearable />
        <!-- <v-text-field dense name="nr_carteira_sus" label="Nº carteirinha SUS" v-model="currentItem.nr_carteira_sus" outlined clearable/> -->


        <v-autocomplete dense name="plano" v-model="currentItem.medico" outlined :loading="isLoading('get:doctors')" 
          :items="doctors" item-text="apelido" item-value="id" return-object clearable style="width: 350px;">
          <template v-slot:label>
            <span>Médico(a) Responsável <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        
        <v-text-field dense name="alergia" label="Alergia" v-model="currentItem.alergia" outlined />
        <v-autocomplete class="flex-item-maximo" dense name="tiposanguineo" label="Tipo sanguíneo" v-model="currentItem.tiposanguineo" outlined :loading="isLoading('get:bloodType')" :items="bloodTypes" item-text="nome" item-value="id" return-object/>
        <!-- <v-text-field dense name="altura" label="Altura" v-model="currentItem.altura" type="Number" outlined /> -->
        <!-- <v-text-field dense name="peso" label="Peso" v-model="currentItem.peso" type="Number" outlined /> -->
        <v-text-field dense name="religiao" label="Religião" v-model="currentItem.religiao" outlined />
        <v-text-field dense name="etinia" label="Etinia" v-model="currentItem.etinia" outlined />
        <v-text-field dense name="profissao" label="Profissao" v-model="currentItem.profissao" outlined />
        <v-text-field dense name="estado_civil" label="Estado Civil" v-model="currentItem.estado_civil"  outlined />
        <v-select class="flex-item-maximo" :rules="requiredField" :items="palliativeOpts" label="Paliativo" item-text="label" item-value="value" v-model="currentItem.paliativo" outlined dense />
        <v-select class="flex-item-maximo" :items="liminarOpts" label="Liminar" item-text="label" item-value="value" v-model="currentItem.liminar" outlined dense/>
        <v-autocomplete dense name="zona" v-model="currentItem.zona" outlined :items="zonas" item-text="nome" item-value="id" clearable >
          <template v-slot:label>
            <span>Zona de residência <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
        <v-text-field v-model="currentItem.data_atendimento" label="Data início do Atendimento" type="date" clearabl outlined dense clearable>
        </v-text-field>
      </div>
        <v-row>
          <v-col>
            <v-tooltip top >
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-text-field dense name="obs" label="Observação" v-model="currentItem.obs" outlined />
                </span>
              </template>
              <span>Essas observações irão para o app no ícone 'Prontuário'.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting" > Salvar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>

    </v-form>
  </div> 
</template> 
<script>

import api from '@/http'
import UtilsFunc from '../../../../service/utilsFunc'
import ConfirmButton from '../../../ConfirmButton.vue'

const { withCRUDUtils } = UtilsFunc

export default {
  name: "Paciente",
  data: () => withCRUDUtils({
    operators: [],
    plans: [],
    doctors: [],
    issuingAgencies: [],
    cids: [],
    zonas:[],
    bloodTypes: [],
    isSubmitting: false,
    liminarOpts: [{ label: 'Sim', value: true }, { label: "Não", value: false }],
    palliativeOpts: [{ label: 'Sim', value: 1 }, { label: "Não", value: 0 }],
    respiradorOpts: [{ label: 'Sim', value:1 }, { label: "Não", value: 0 }],
    biologicalSex: [{ label: 'Masculino', value: 'MASCULINO' }, { label: "Feminino", value: 'FEMININO' }],
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    emailRules: [
      // v => !!v || 'E-mail é obrigatório',
       v => !v || /.+@.+\..+/.test(v) || 'E-mail deve ser válido'
    ],
    responsavelRules: [],
    cpfResponsavelRules: [],
    data_nascimento: null,
  }),
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    loading: Boolean,
    formRef: Object,
    currentItem: { type: Object, default: null },
  },
  computed: {
    cpfRules() {
      return [
        v => !v || this.validateCPF(v) || 'CPF inválido'
      ];
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!( this.currentItem.nome && 
        this.currentItem.data_nascimento && 
        this.currentItem.operadora && 
        this.currentItem.plano &&
        this.currentItem.cid &&
        this.currentItem.telefone && 
        this.currentItem.zona &&
        this.currentItem.medico &&
        this.currentItem.sexo_biologico && 
        (!this.currentItem.necessita_responsavel || (this.currentItem.responsavel && this.currentItem.cpf_responsavel)));
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    idadePaciente() {
      if (!this.currentItem.data_nascimento) {
        return '';
      }
      
      const today = new Date();
      const birthDate = new Date(this.currentItem.data_nascimento);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();

      // Se o mês atual for anterior ao mês de nascimento ou se for o mesmo mês, mas o dia atual for anterior ao dia de nascimento, subtraímos 1 da idade
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    }
  },

  methods: {
    formatPatientName() {
      if (this.currentItem && this.currentItem.nome) {
        const lowercaseWords = ["a","e", "i", "o", "u", "de", "des", "da", "das", "do", "dos"]; // Palavras que devem permanecer em minúsculas
        this.currentItem.nome = this.currentItem.nome
          .toLowerCase()
          .split(" ")
          .map((word, index) => {
            // Mantém a primeira palavra em maiúsculas mesmo que esteja na lista
            if (index === 0 || !lowercaseWords.includes(word)) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word; // Retorna as palavras em minúsculas se estiverem na lista
          })
          .join(" ");
      }
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      if (this.currentItem.cpf) {
      this.currentItem.cpf = this.currentItem.cpf.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      }
      if (this.currentItem.cpf_responsavel) {
      this.currentItem.cpf_responsavel = this.currentItem.cpf_responsavel.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
      }
      this.isSubmitting = true;
      this.onSubmit({
        ...this.currentItem,
        ativo: Boolean(this.currentItem.ativo),
      }).finally(() => {
      this.isSubmitting = false;
      });
    },
    async getPlans(planoId) {
      const LOADING_NAME = 'get:plans'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`operadoras/planos/?operadora=${planoId.id}`)
        this.plans = data.filter(plan => plan.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true) 
      }
    },
    async getOperators() {
      const LOADING_NAME = 'get:operators'
      this.setLoading(LOADING_NAME)
      const { data } = await api.get('operadoras/operadoras/')
      this.setLoading(LOADING_NAME, true)
      this.operators = data.filter(operator => operator.ativo);
    },
    async getDoctors() {
      const LOADING_NAME = 'get:doctors'
      this.setLoading(LOADING_NAME)
      const { data } = await api.get(`contas/medicos/`)
      this.setLoading(LOADING_NAME, true)
      this.doctors = data;
      // console.log(this.doctors)
    },
    async getIssuingAgency() {
      const LOADING_NAME = 'get:issuingAgency'
      this.setLoading(LOADING_NAME)
      const { data } = await api.get('uteis/orgao_exp/')
      this.setLoading(LOADING_NAME, true)
      this.issuingAgencies = data.filter(agency => agency.ativo);
    },
    async getBloodType() {
      const LOADING_NAME = 'get:bloodType'
      this.setLoading(LOADING_NAME)
      const { data } = await api.get('uteis/tiposanguineo/')
      this.setLoading(LOADING_NAME, true)
      this.bloodTypes = data.filter(bloodType => bloodType.ativo);
    },
    async getCIDS() {
      const { data } = await api('uteis/cids/')
      this.cids = data.filter(cid => cid.ativo);
    },
    async getZonas(){
      const { data } = await api('contas/zonas/')
      this.zonas = data.filter(zona => zona.ativo);
      // console.log(this.zonas)
    },
    validateCPF(cpf) {
      if (!cpf) return false;
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf == '') return false;
      if (cpf.length != 11 ||
          cpf == "00000000000" ||
          cpf == "11111111111" ||
          cpf == "22222222222" ||
          cpf == "33333333333" ||
          cpf == "44444444444" ||
          cpf == "55555555555" ||
          cpf == "66666666666" ||
          cpf == "77777777777" ||
          cpf == "88888888888" ||
          cpf == "99999999999")
          return false;
      let add = 0;
      for (let i = 0; i < 9; i++)
          add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      add = 0;
      for (let i = 0; i < 10; i++)
          add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    },
    checkNecessitaResponsavel() {
      if (this.idadePaciente < 18) {
        this.currentItem.necessita_responsavel = true;
      }
    }
  },
  watch: {
    // Assista por mudanças no campo 'necessita_responsavel' para definir as regras dinamicamente
    'currentItem.necessita_responsavel': function (newValue) {
      if (newValue === true) {
        // Se 'necessita_responsavel' for SIM (true), os campos 'responsavel' e 'cpf_responsavel' são obrigatórios
        this.responsavelRules = [v => !!v || 'Responsável é obrigatório'];
        this.cpfResponsavelRules = [v => !!v || 'CPF do responsável é obrigatório', v => this.validateCPF(v) || 'CPF inválido'];
      } else {
        // Caso contrário, os campos não são obrigatórios
        this.responsavelRules = [];
        this.cpfResponsavelRules = [];
      }
    },
    'currentItem.operadora.id': function () {
      if (this.currentItem.operadora) this.getPlans(this.currentItem.operadora)
    },
    // Verifica a idade sempre que a data de nascimento mudar
    idadePaciente(newAge) {
      if (newAge < 18) {
        this.currentItem.necessita_responsavel = true;
      }
    },
  },
  mounted() {
    this.getIssuingAgency()
    this.getCIDS()
    this.getZonas()
    this.getOperators()
    this.getDoctors()
    this.getBloodType()
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.currentItem.alergia = this.currentItem.alergia || "Nega Alergia"
    this.currentItem.paliativo = Number(this.currentItem.paliativo)
    this.currentItem.respirador = Number(this.currentItem.respirador)
    this.currentItem.visita_fimsemana_feriado = Number(this.currentItem.visita_fimsemana_feriado)
    this.currentItem.liminar = this.currentItem.liminar || false
    if (this.currentItem.operadora) this.getPlans(this.currentItem.operadora)
    this.checkNecessitaResponsavel();
  }
}
</script>

<style scoped>
div.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.flex-item {
  flex: 1 1 200px; 
  min-width: 300px; 
}
.flex-item-nome {
  flex: 1 1 200px; 
  min-width: 400px; 
}
.flex-item-maximo {
  flex: 1 1 200px; 
  max-width: 200px; 
}
.idade-text {
  margin-top: 3px;
  font-weight: normal;
  color: #333;
}

</style>
